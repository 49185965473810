// src/config/config.js
const config = {
   API_BASEURL: 'https://dev.creditpredict.in/api/v1',
   API_LOGIN_URL:'/auth/signin',
   SIGNUP_URL:'/auth/signup',
   

   HOME_URL:'/home',
   API_CLIENT_URL:'/clients',
   API_USERS_URL:'/users',
   API_ROLE_URL:'/role',
   scope:'crdprd',
   grant_type:'client_credentials',
   auth_token_url:'/Oauth/token',
   API_KEYS_URL:'/api_keys',
   API_SAVE_URL:'/save_selection',
   
   API_ROUTE_URL: "https://dev.creditpredict.in/api/v1/apiheader",
 API_DETAIL_URL: "https://dev.creditpredict.in/api/v1/api-details",
 API_DETAIL_GET_URL: "https://dev.creditpredict.in/api/v1/api-get-details",
 API_DETAIL_POST_URL: "https://dev.creditpredict.in/api/v1/api-post-details",
 DISTRICT_API_URL:"/distData"
 
    // Update with your backend URL
 };
 
 export default config;
 