import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Select, message,Card } from 'antd';
import axios from 'axios';
import config from '../src/config/config';

const { Option } = Select;

const UserMapping = () => {
    const [userMappings, setUserMappings] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingMapping, setEditingMapping] = useState(null);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    
    const [form] = Form.useForm();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [userResponse, roleResponse, mappingResponse] = await Promise.all([
                    axios.get(`${config.API_BASEURL}/users`),
                    axios.get(`${config.API_BASEURL}/adm_role`),
                    axios.get(`${config.API_BASEURL}/user_mapping`)
                ]);

                // Set users and roles once
                setUsers(userResponse.data);
                setRoles(roleResponse.data);

                // After users and roles are set, map userMappings
                const mappings = mappingResponse.data.map(mapping => ({
                    key: mapping.id,
                    user: userResponse.data.find(user => user.id === mapping.user_id)?.username || 'Unknown',
                    role: roleResponse.data.find(role => role.role_id === mapping.role_id)?.role_desc || 'Unknown',
                    user_id: mapping.user_id,
                    role_id: mapping.role_id
                }));
                setUserMappings(mappings);

            } catch (error) {
                message.error('Failed to load data');
            }
        };

        fetchData(); 
    }, []); 

    const showModal = (mapping = null) => {
        setEditingMapping(mapping);
        if (mapping) {
            // Pre-fill the form fields when editing
            form.setFieldsValue({
                user: mapping.user_id,
                role: mapping.role_id,
            });
        } else {
            form.resetFields();
        }
        setIsModalVisible(true);
    };

    // const handleSubmit = async (values) => {
    //     const userId = values.user;  // Capture the user ID
    //     const roleId = values.role;  // Capture the role ID

    //     if (editingMapping) {
    //         try {
    //             await axios.put(`${config.API_BASEURL}/user_map/edit`, {
    //                 id: editingMapping.key,
    //                 user_id: userId,  
    //                 role_id: roleId   
    //             });

    //             // Update the mapping in local state immediately
    //             const updatedMapping = userMappings.map(mapping =>
    //                 mapping.key === editingMapping.key
    //                     ? { ...mapping, user_id: userId, role_id: roleId, user: users.find(user => user.id === userId)?.username, role: roles.find(role => role.role_id === roleId)?.role_desc }
    //                     : mapping
    //             );
    //             setUserMappings(updatedMapping);  // Update the state

    //             message.success('User mapping updated successfully');
    //         } catch (error) {
    //             message.error('Failed to update user mapping');
    //         }

    //     } else {
            
    //         try {

    //             const response = await axios.post(`${config.API_BASEURL}/user_mapping/post`,values);

    //             const newMapping = {
    //                 key: response.data.id,
    //                 user: users.find(user => user.id === userId)?.username, 
    //                 role: roles.find(role => role.role_id === roleId)?.role_desc, 
    //                 user_id: userId, 
    //                 role_id: roleId  
    //             };
    //             setUserMappings([...userMappings, newMapping]); 

    //             message.success('User mapping added successfully');
    //         } catch (error) {
    //             message.error('Failed to add user mapping');
    //         }
    //     }

    //     form.resetFields();
    //     setIsModalVisible(false);
    //     setEditingMapping(null);
    // };

    const handleSubmit = async (values) => {
        const userId = values.user;  // Capture the user ID
        const roleId = values.role;  // Capture the role ID
    
        // Check if user already has this role (only for creating new mappings)
        if (!editingMapping) {
            const existingMapping = userMappings.find(
                mapping => mapping.user_id === userId && mapping.role_id === roleId
            );
    
            if (existingMapping) {
                // Show error message and prevent the request from going further
                message.error('This user already has the selected role.');
                return;
            }
        }
    
        if (editingMapping) {
            try {
                await axios.put(`${config.API_BASEURL}/user_map/edit`, {
                    id: editingMapping.key,
                    user_id: userId,  
                    role_id: roleId   
                });
    
                // Update the mapping in local state immediately
                const updatedMapping = userMappings.map(mapping =>
                    mapping.key === editingMapping.key
                        ? { ...mapping, user_id: userId, role_id: roleId, user: users.find(user => user.id === userId)?.username, role: roles.find(role => role.role_id === roleId)?.role_desc }
                        : mapping
                );
                setUserMappings(updatedMapping);  // Update the state
    
                message.success('User mapping updated successfully');
            } catch (error) {
                if(error.status === 400){
                    message.warning("This User is already mapped to the role.");
                    }
                    else{
                message.error('Failed to update user mapping,role already present');
            }
        }
    
        } else {
            // Handle Create (No duplicates allowed because of the check above)
            try {
                const response = await axios.post(`${config.API_BASEURL}/user_mapping/post`, values);
    
                const newMapping = {
                    key: response.data.id,  // ID from backend response
                    user: users.find(user => user.id === userId)?.username, // Get user name for display
                    role: roles.find(role => role.role_id === roleId)?.role_desc, // Get role description for display
                    user_id: userId,  // Store user_id for future checks
                    role_id: roleId   // Store role_id for future checks
                };
                setUserMappings([...userMappings, newMapping]);  // Add the new mapping to the state
    
                message.success('User mapping added successfully');
            } catch (error) {
                
                
                message.error('Failed to add user mapping');
                    
            }
        }
    
        // Reset form and close modal
        form.resetFields();
        setIsModalVisible(false);
        setEditingMapping(null);
    };
    

    const handleDeleteMapping = async (key) => {
        try {
            await axios.delete(`${config.API_BASEURL}/user_map/delete`, { data: { id: key } });
            setUserMappings(userMappings.filter(mapping => mapping.key !== key));
            message.success('User mapping deleted successfully');
        } catch (error) {
            message.error('Failed to delete user mapping');
        }
    };

    const columns = [
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button color='primary' variant="solid" onClick={() => showModal(record)} style={{marginRight:"20px"}}>Edit</Button>
                    <Button color='danger' variant="solid"  onClick={() => handleDeleteMapping(record.key)}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <>
        <Card className="grid-card">
            <div >
  
        <h4 style={{gap:'20px',padding:'20px',textAlign:'center',color:'#006dff'}}>User Role Mapping</h4>
            <Button type="primary"  onClick={() => showModal()} >Add</Button>
            <Table columns={columns} style={{marginTop:"20px"}} dataSource={userMappings} rowKey="key" />

            <Modal
                title={editingMapping ? "Edit User Mapping" : "Add New User Mapping"}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="user"
                        label="User"
                        rules={[{ required: true, message: 'Please select a user!' }]}
                    >
                        <Select placeholder="Select a user">
                            {users.map(user => (
                                <Option key={user.id} value={user.id}>{user.username}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="role"
                        label="Role"
                        rules={[{ required: true, message: 'Please select a role!' }]}
                    >
                        <Select placeholder="Select a role">
                            {roles.map(role => (
                                <Option key={role.role_id} value={role.role_id}>{role.role_desc}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {editingMapping ? 'Update' : 'Save'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            </div>
            </Card>
        </>
    );
};

export default UserMapping;

