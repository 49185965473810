import React from 'react';

const Footer = () => {
  const footerStyle = {
    backgroundColor: '#f8f9fa',
    padding: '20px',
    textAlign: 'center',
    position: 'fixed',
    width: '100%',
    bottom: '0',
  };

  const textStyle = {
    margin: '0',
    fontSize: '14px',
    color: '#6c757d',
  };

  const linkStyle = {
    color: '#007bff',
    margin: '0 5px',
    textDecoration: 'none',
  };

  const linkHoverStyle = {
    textDecoration: 'underline',
  };

  return (
    <footer style={footerStyle}>
      <div>
        <p style={textStyle}>&copy; 2024 Tekplay & kaavian Systems. All rights reserved.</p>
        <p>
          <a href="/privacy-policy" style={linkStyle} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>
            Privacy Policy
          </a> 
          | 
          <a href="/terms-of-service" style={linkStyle} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>
            Terms of Service
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
