import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Space, message, Card, Tooltip } from 'antd';
import { FilePdfOutlined, CopyOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axiosInstance from './config/axiosInstance'; // Use the configured Axios instance
import config from './config/config';
import ExportClientPDF from './ExportClientPDF'; // Import the PDF export component
import logo from './assets/images/logo.png';

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingClient, setEditingClient] = useState(null);
    const [isViewMode, setIsViewMode] = useState(false);
    const [form] = Form.useForm();
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    
    // Loading states
    const [loadingTable, setLoadingTable] = useState(true);  // For table data loading
    const [loadingSubmit, setLoadingSubmit] = useState(false);  // For form submission

    // Fetch clients from API on page load
    useEffect(() => {
        fetchClients();
    }, []);

    // Fetch clients from the API
    const fetchClients = async () => {
        setLoadingTable(true);  // Start loading
        try {
            const response = await axiosInstance.get(`${config.API_CLIENT_URL}`); // Replace with your API endpoint
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
            message.error('Failed to load clients');
        } finally {
            setLoadingTable(false);  // Stop loading after data is fetched
        }
    };

    // Open modal for Add/Edit or View
    const showModal = (client = null, viewMode = false) => {
        setEditingClient(client);
        setIsViewMode(viewMode);
        if (client) {
            form.setFieldsValue(client);
        } else {
            form.resetFields();
        }
        setIsModalVisible(true);
    };

    // Handle form submission (Add/Edit)
    const handleSubmit = async (values) => {
        setLoadingSubmit(true);  // Start submit loading
        try {
            // Using POST for both Add and Update; `id` is checked to determine action
            const response = await axiosInstance.post(`${config.API_CLIENT_URL}`, { ...values, id: editingClient ? editingClient.id : null }); // Replace with your API endpoint

            if (editingClient) {
                // Update local client data
                setClients(clients.map(client => (client.id === editingClient.id ? { ...client, ...response.data } : client)));
                message.success('Client updated successfully');
            } else {
                // Add new client
                setClients([...clients, { ...response.data, key: Date.now() }]);
                message.success('Client added successfully');
            }
        } catch (error) {
            console.error('Error saving client:', error);
            message.error('Failed to save client');
        } finally {
            setLoadingSubmit(false);  // Stop submit loading
        }

        form.resetFields();
        setIsModalVisible(false);
        setEditingClient(null);
        setIsViewMode(false);
    };

    // Handle delete client
    const handleDeleteClient = async (id) => {
        try {
            await axiosInstance.delete(`/api/clients/${id}`); // Replace with your API endpoint
            setClients(clients.filter(client => client.id !== id));
            message.success('Client deleted successfully');
        } catch (error) {
            console.error('Error deleting client:', error);
            message.error('Failed to delete client');
        }
    };


    // Generate cURL command
    const generateCurlCommand = (client) => {
        return `curl -X POST ${config.API_CLIENT_URL} \\
-H "Content-Type: application/json" \\
-d '{"client_id": "${client.client_id}", "client_secret": "${client.client_secret}", "client_token": "${client.client_token}"}'`;
    };

   // Copy cURL function
const copyCurlCommand = (client) => {
    let curlCommand = '';

    if (client.client_token) {
        // If token exists
        curlCommand = `curl --location '${config.API_BASEURL}${config.DISTRICT_API_URL}' \\
        --header 'Content-Type: application/json' \\
        --header 'Authorization: Bearer ${client.client_token}' \\
        --data '{
            "DISPLAY_NAME" : "PP-2"
        }'`;
    } else {
        // If no token
        curlCommand = `curl -X POST '${config.API_BASEURL}${config.auth_token_url}' -H 'client_id: ${client.client_id}' -H 'client_secret: ${client.client_secret}' -H 'scope: crdprd' -H 'grant_type: client_credentials'`;
    }

    navigator.clipboard.writeText(curlCommand)
        .then(() => message.success('cURL copied to clipboard!'))
        .catch((err) => message.error('Failed to copy cURL'));
};

/*
    // Export to PDF function
    const exportToPDF = async (client) => {
        const doc = new jsPDF();

        const curlWithoutToken = `curl -X POST '${config.API_BASEURL}${config.auth_token_url}' -H 'client_id: ${client.client_id}' -H 'client_secret: ${client.client_secret}' -H 'scope: crdprd' -H 'grant_type: client_credentials'`;
    
        const curlWithToken = `curl --location '${config.API_BASEURL}${config.DISTRICT_API_URL}' \\
        --header 'Content-Type: application/json' \\
        --header 'Authorization: Bearer ${client.client_token}' \\
       
        --data '{
            "DISPLAY_NAME" : "PP-2"
        }'`;
    

        const content = `
            Client Name: ${client.client_name}
            Email: ${client.email}
            Mobile No: ${client.mobile_no}
            Address: ${client.address}
            Client ID: ${client.client_id}
            Client Secret: ${client.client_secret}
            Client Token: ${client.client_token}

            Client Oauth Curl:${curlWithoutToken}

            Client district Api Curl:${client.client_token ? curlWithToken : 'Please Generate Token First'}

        `;
        const splitContent = doc.splitTextToSize(content, 180); // Split content to fit in the page
        doc.text(splitContent, 10, 10);
        doc.save(`${client.client_name}-details.pdf`);
    };
*/

const exportToPDF = async (client) => {

    debugger;
    const doc = new jsPDF();

    const curlWithoutToken = `curl -X POST '${config.API_BASEURL}${config.auth_token_url}' -H 'client_id: ${client.client_id}' -H 'client_secret: ${client.client_secret}' -H 'scope: crdprd' -H 'grant_type: client_credentials'`;
    
    const curlWithToken = `curl --location '${config.API_BASEURL}${config.DISTRICT_API_URL}' \\
    --header 'Content-Type: application/json' \\
    --header 'Authorization: Bearer ${client.client_token}' \\
   
    --data '{
        "DISPLAY_NAME" : "PP-2"
    }'`;

    // Add logo
//    const logo = new Image();
 //   logo.src = 'assets/images/logo.png'; // Replace with your logo path
  //  logo.onload = async () => {
        doc.addImage(logo, 'PNG', 10, 10,70, 25); // Add logo at position x=10, y=10, width=40, height=40

        // Draw a border
        doc.setLineWidth(1);
        doc.rect(5, 5, 200, 290); // x, y, width, height

        // Header
        doc.setFontSize(16);
        doc.text('Client Details', 10, 60);
        doc.setFontSize(10);
        doc.text(`Generated on: ${new Date().toLocaleString()}`, 10, 65);
        doc.line(10, 68, 200, 68); // Horizontal line below the header
debugger;
        // Client details
        const content = `
            Client Name: ${client.client_name}
            Email: ${client.email}
            Mobile No: ${client.mobile_no}
            Address: ${client.address}
            Client ID: ${client.client_id}
            Client Secret: ${client.client_secret}
            Client Token: ${client.client_token}

            Client OAuth Curl: ${curlWithoutToken}

            Client District API Curl: ${client.client_token ? curlWithToken : 'Please Generate Token First'}
        `;
        
        const splitContent = doc.splitTextToSize(content, 180); // Split content to fit in the page
        doc.text(splitContent, 10, 80); // Add content below the header

 
        // Footer
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, 10, 290); // Footer at the bottom
            doc.text('Confidential - For Internal Use Only', 170, 290, { align: 'right' });
        }

        // Save PDF
        doc.save(`${client.client_name}-details.pdf`);
    
};



    // Define columns for the table with overflow handling
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50, // Adjust column width
            sorter: (a, b) => a.id - b.id, // Sorting by ID
        },
        {
            title: 'Client Name',
            dataIndex: 'client_name',
            key: 'client_name',
            width: 120,
            ellipsis: true,
            sorter: (a, b) => (a.client_name || '').localeCompare(b.client_name || ''),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 150,
            ellipsis: true,
            sorter: (a, b) => (a.email || '').localeCompare(b.email || ''), // Sorting by Email
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobile_no',
            key: 'mobile_no',
            width: 120,
            ellipsis: true,
            sorter: (a, b) => (a.mobile_no || '').localeCompare(b.mobile_no || ''),
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 150,
            ellipsis: true,
            sorter: (a, b) => (a.address || '').localeCompare(b.address || ''),
        },
        {
            title: 'Client ID',
            dataIndex: 'client_id',
            key: 'client_id',
            width: 100,
            ellipsis: true,
            className: 'overflow-cell',
            sorter: (a, b) => a.client_id - b.client_id,
        },
        {
            title: 'Client Secret',
            dataIndex: 'client_secret',
            key: 'client_secret',
            width: 150,
            ellipsis: true,
            className: 'overflow-cell', // Add this class
            sorter: (a, b) => (a.client_secret || '').localeCompare(b.client_secret || ''),
            
        },
        {
            title: 'Client Token',
            dataIndex: 'client_token',
            key: 'client_token',
            width: 150,
            ellipsis: true,
            className: 'overflow-cell', // Add this class
            sorter: (a, b) => (a.client_token || '').localeCompare(b.client_token || ''),

        },
        {
            title: 'Actions',
            key: 'actions',
            width: 200,
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => showModal(record)}>Edit</Button>
                    <Button onClick={() => showModal(record, true)}>View</Button>
                    <Tooltip title="Copy cURL">
                        <CopyOutlined 
                            style={{ fontSize: '24px', cursor: 'pointer', marginLeft: '8px' }} 
                            onClick={() => copyCurlCommand(record)} 
                        />
                    </Tooltip>
                    <Tooltip title="Export to PDF">
                        <FilePdfOutlined 
                            style={{ fontSize: '24px', color: '#ff4d4f', cursor: 'pointer', marginLeft: '8px' }} 
                            onClick={() => exportToPDF(record)} 
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <Card className="grid-card">
            <div style={{ padding: '20px' }}>
                <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#1890ff' }}>Client</h2>
                <Button type="primary" onClick={() => showModal()} className="add-button">
                    Add 
                </Button>
                <div style={{ overflowX: 'auto' }}> {/* Enables horizontal scrolling */}
                    <Table
                        columns={columns}
                        dataSource={clients}
                        rowKey="id"
                        className="grid-table"
                        size="small"
                        loading={loadingTable}  // Add loader for the table
                        pagination={{
                            ...pagination,
                            pageSizeOptions: ['5', '10', '20', '30'], // Options for number of items per page
                            showSizeChanger: true, // Show size changer
                            onShowSizeChange: (current, size) => setPagination({ current, pageSize: size }), // Handle size change
                            onChange: (page, pageSize) => setPagination({ current: page, pageSize }), // Handle page change
                        }}
                        scroll={{ x: true }} // Enable horizontal scroll
                        responsive
                    />
                </div>
                
                {/* Modal for adding/editing/viewing clients */}
                <Modal
                    title={editingClient ? (isViewMode ? "View Client" : "Edit Client") : "Add New Client"}
                    visible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                    className="grid-modal"
                    maskClosable={false} // Prevent closing modal by clicking outside
                >
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        layout="vertical"
                        size="small"
                    >
                        <Form.Item
                            name="client_name"
                            label="Client Name"
                            rules={[{ required: true, message: 'Please input the client name!' }]}
                        >
                            <Input placeholder="Enter Client Name" disabled={isViewMode} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                        >
                            <Input placeholder="Enter Email" disabled={isViewMode} />
                        </Form.Item>
                        <Form.Item
                            name="mobile_no"
                            label="Mobile No"
                            rules={[
                                { required: true, message: 'Please input the mobile number!' },
                                {
                                    pattern: /^(?!0)(?!.*[a-zA-Z])(?:\+91[-\s]?)?[789]\d{9}$/,
                                    message: 'Please enter a valid  mobile number!',
                                },
                            ]}

                        >
                            <Input placeholder="Enter Mobile No" disabled={isViewMode} />
                        </Form.Item>
                        <Form.Item
                            name="address"
                            label="Address"
                            rules={[{ required: true, message: 'Please input the address!' }]}
                        >
                            <Input placeholder="Enter Address" disabled={isViewMode} />
                        </Form.Item>
                        {/* Conditionally render sensitive fields only in view mode */}
                        {isViewMode && (
                            <>
                                <Form.Item name="client_id" label="Client ID">
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item name="client_secret" label="Client Secret">
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item name="client_token" label="Client Token">
                                    <Input disabled />
                                </Form.Item>
                            </>
                        )}
                        {!isViewMode && (
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loadingSubmit}>  {/* Show loader on submit button */}
                                    {editingClient ? "Update" : "Add"} Client
                                </Button>
                            </Form.Item>
                        )}
                    </Form>
                </Modal>
            </div>
        </Card>
    );
};

export default Clients;
