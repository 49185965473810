import React from 'react';
import { BrowserRouter as Router, Route, Routes,useRoutes,Navigate  } from 'react-router-dom';
import './assets/css/nav.css'; // Add your custom CSS
import './assets/css/styles.css'; // Add your custom CSS
import Navbar from './Navbar';
import { useAuth } from './AuthContext'; // Import the Auth context
import RoutesConfig from './routes'; // Import the routes
import Footer from './Footer';


// Main App component
const App = () => {
  const routing = useRoutes(RoutesConfig()); // Use the routes

  return (
     <> 
        <Navbar />
        <div className="container mt-4">
        {routing}
        </div>
        <Footer/>
        </>
    );
};




export default App;
