// src/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context for authentication and session management
const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [sessionData, setSessionData] = useState(null);

  // Function to log in
  const login = (data) => {
    setIsAuthenticated(true);
    setSessionData(data); // Set session data on login
  };

  // Function to log out
  const logout = () => {
    setIsAuthenticated(false);
    setSessionData(null); // Clear session data on logout
  };

  // Example: Persist session data in local storage (optional)
  useEffect(() => {
    const storedSession = localStorage.getItem('sessionData');
    if (storedSession) {
      setSessionData(JSON.parse(storedSession));
      setIsAuthenticated(true); // Assume user is authenticated if session data exists
    }
  }, []);

  // Example: Save session data to local storage when it changes (optional)
  useEffect(() => {
    if (sessionData) {
      localStorage.setItem('sessionData', JSON.stringify(sessionData));
    } else {
      localStorage.removeItem('sessionData');
    }
  }, [sessionData]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, sessionData }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for using AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
