import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import the Auth context
import logo from './assets/images/logo.png'; 
import './assets/css/navCustom.css';// Make sure to import your logo

const Navbar = () => {
   const { isAuthenticated, logout, sessionData } = useAuth(); // Get auth state, logout function, and session data
   const [activeMenu, setActiveMenu] = useState(null); // State to track active menu item

   // Function to handle menu item hover
  const handleMouseEnter = (menu) => {
    setActiveMenu(menu); // Set the active menu on hover
  };

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    setActiveMenu(null); // Reset the active menu when mouse leaves
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" width="300" height="30" className="d-inline-block align-text-top" />
          
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {isAuthenticated && sessionData ? (
              <>
           
            <li
              className={`nav-item ${activeMenu === 'home' ? 'active' : ''}`}
              onMouseEnter={() => handleMouseEnter('home')}
              onMouseLeave={handleMouseLeave}
            >
              <Link className="nav-link" to="/">Home</Link>
            </li>


 {/* Submenu */}
 <li
              className={`nav-item dropdown ${activeMenu === 'client' ? 'active' : ''}`}
              onMouseEnter={() => handleMouseEnter('client')}
              onMouseLeave={handleMouseLeave}
            >
              <Link className="nav-link dropdown-toggle" to="/client" id="servicesDropdown" role="button" data-toggle="dropdown">
                Clients Process
              </Link>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/client/creation">Client Details</Link></li>
                <li><Link className="dropdown-item" to="/client/token">Token Generation</Link></li>
                
              </ul>
            </li>


 <li
              className={`nav-item dropdown ${activeMenu === 'user' ? 'active' : ''}`}
              onMouseEnter={() => handleMouseEnter('user')}
              onMouseLeave={handleMouseLeave}
            >
              <Link className="nav-link dropdown-toggle" to="/user" id="servicesDropdown" role="button" data-toggle="dropdown">
                Clients
              </Link>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/user/privilege">Client Privilege</Link></li>
                <li><Link className="dropdown-item" to="/user/apiData">APi Master</Link></li>
                <li><Link className="dropdown-item" to="/user/Role">Role</Link></li>
                <li><Link className="dropdown-item" to="/user/ApiUser">User Role Mapping</Link></li>
                <li><Link className="dropdown-item" to="/user/RoleApI">Role API Mapping</Link></li>

                
              </ul>
            </li>


            <li className="nav-item">
                  <Link className="nav-link" to="/signup">Sign Up</Link>
                </li>
           
            <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={logout}>Logout</button>
                </li>

            </>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/login">Login</Link>
                </li>
              </>
            )}
          </ul>

        </div>
      </div>
    </nav>
  );
};

export default Navbar;
