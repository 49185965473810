import React, { useState } from 'react';
import { useAuth } from './AuthContext'; // Import the Auth context
import { useNavigate, Link } from 'react-router-dom'; // For navigation
import axiosInstance from './config/axiosInstance'; // Use the configured Axios instance
import config from './config/config';
import './assets/css/login.css'


const Login = () => {
  const { login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // For navigation

  const handleSubmit = async (e) => {
      e.preventDefault();
  debugger;
      try {
        const response = await axiosInstance.post(`${config.API_LOGIN_URL}`, {
          username,
          password,
        });
  
        // Assuming the response contains the user data
        if (response.data.success) {
          // Successful login
          login(response.data.user); // Call the login function from context
          navigate(`${config.HOME_URL}`); // Redirect to home page
        } else {
          // Failed login
          setError(response.data.message); // Set error message
        }
      } catch (err) {
        setError('An error occurred. Please try again.');
        console.error(err); // Log the error for debugging
      }
  
  };

  return (
    <div className="mt-5">
    <div className="row justify-content-center">
      <div className="col-md-4">
        <div className="card shadow p-4">
          <h2 className="text-center mb-4" style={{ fontSize: '2rem' }}>Login</h2>
          {error && <div className="alert alert-danger">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
            <label htmlFor="username" className="form-label" style={{ fontSize: '1.2rem' }}>Username :</label>
        <input
          type="text"
          className="form-control form-control-lg" // Larger control for better visibility
          name="username"
          placeholder="Username"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label" style={{ fontSize: '1.2rem' }}>Password:</label>
              <input
                type="password"
                className="form-control form-control-lg" // Larger control for better visibility
                placeholder="password"

                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary btn-lg w-100">Login</button>
          {/*            <p className="mt-3 text-center">Don't have an account? <Link to="/signup">Sign up</Link></p>*/}
          </form>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Login;
