import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Switch,
  Table,
  Card
} from "antd";
import Column from "antd/es/table/Column";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "./config/config";

const ApiMaster = () => {
  const [data, setData] = useState([]);
  const [dataDtl, setDataDtl] = useState(null);
  const [dataDtlRows, setDataDtlRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [detailsEditRec, setDetailsEditRec] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); 
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.API_ROUTE_URL}`);
      setData(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async (values) => {
    const method = editRecord ? "PUT" : "POST";
    const url = editRecord
      ? `${config.API_ROUTE_URL}/${editRecord.api_id}`
      : `${config.API_ROUTE_URL}`;

    try {
      await axios({
        method,
        url,
        headers: { "Content-Type": "application/json" },
        data: values,
      });
      message.success(
        editRecord ? "API updated successfully!" : "API added successfully!"
      );
      setIsModalOpen(false);
      form.resetFields();
      fetchData();
    } catch (error) {
      message.error(`Something went wrong: ${error.message}`);
    }
  };

  const openModal = (record = null) => {
    setEditRecord(record);
    setIsModalOpen(true);
    if (record) {
      form.setFieldsValue(record);
    } else {
      form.resetFields();
    }
  };

  const fetchDetails = async (api_id) => {
    try {
      const response = await axios.post(
        `${config.API_DETAIL_GET_URL}`,
        { api_id } // Send the api_id in the body
      );

      console.log("res data", response.data);
      setDataDtl(response.data);
      setDataDtlRows(response.data);
      setIsDetailsModalOpen(true); // Open the details modal
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const handleAddRow = () => {
    setDataDtlRows((prev) => [
      ...prev,
      { display_column: "", json_column: "", is_active: true },
    ]);
  };

  const handleDeleteRow = (index) => {
    const rowToDelete = dataDtlRows[index];
    if (!rowToDelete.display_column && !rowToDelete.json_column) {
      setDataDtlRows((prev) => {
        const updatedRows = [...prev];
        updatedRows.splice(index, 1);
        return updatedRows;
      });
      message.success("Row deleted successfully!");
    } else {
      message.warning("Only empty rows can be deleted!");
    }
  };

  const handleToggleActive = (index) => {
    const updatedRows = [...dataDtlRows];
    updatedRows[index].is_active = !updatedRows[index].is_active; // Toggle the is_active state
    setDataDtlRows(updatedRows); // Update the rows with the new state
  };

  const handleSave = async (api_id) => {
    if (!api_id) {
      console.log("api_id is null");
    }

    const filteredRows = dataDtlRows.filter(
      (row) => row.display_column.trim() !== "" || row.json_column.trim() !== ""
    );

    if (filteredRows.length === 0) {
      message.warning("No valid API details to save.");
      return;
    }

    try {
      await axios.post(`${config.API_DETAIL_POST_URL}`, {
        api_id,
        details: filteredRows,
      });
      message.success("API details saved successfully!");
      setDetailsEditRec(false);
      // setIsDetailsModalOpen(false)
    } catch (error) {
      message.error(`Failed to save API details: ${error.message}`);
    }
  };

  const columns = [
    { title: "API ID", dataIndex: "api_id", key: "api_id" },
    { title: "API Name", dataIndex: "api_name", key: "api_name" },
    { title: "API Route", dataIndex: "api_route", key: "api_route" },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button
            type="primary"
            style={{ marginRight: 20 }}
            onClick={() => {
              fetchDetails(record.api_id);
              setDataDtlRows([]); // Clear previous rows to allow adding new ones
            }}
          >
            Add Details
          </Button>
          <Button type="danger" onClick={() => openModal(record)}>
            Edit
          </Button>
        </>
      ),
    },
  ];

  if (loading) return <Spin tip="Loading API data..." />;
  if (error)
    return <Alert message="Error" description={error} type="error" showIcon />;

  return (
    <div>
       <Card className="grid-card">
            <div style={{ padding: '20px' }}>
       
      <h2>API Master Data</h2>
      <Button type="primary" onClick={() => openModal()}>
        Add
      </Button>
      <Table
        dataSource={data}
        columns={columns}
        rowKey="api_id"
        style={{ marginTop: 20 }}
      />

      <Modal
        title={editRecord ? "Edit API" : "Add API"}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}
        okText={editRecord ? "Update" : "Save"}
      >
        <Form form={form} onFinish={handleFormSubmit} layout="vertical">
          <Form.Item
            label="API Name"
            name="api_name"
            rules={[{ required: true, message: "Please input the API name!" }]}
          >
            <Input placeholder="Enter API Name" />
          </Form.Item>
          <Form.Item
            label="API Route"
            name="api_route"
            rules={[{ required: true, message: "Please input the API route!" }]}
          >
            <Input placeholder="Enter API Route" />
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal for Details */}
      <Modal
        title="API Details"
        open={isDetailsModalOpen}
        centered
        onCancel={() => {
          setIsDetailsModalOpen(false);
          setDetailsEditRec(false);
        }}
        footer={null}
        width={900}
      >
        {dataDtl && (
          <div
            style={{
              paddingBottom: "80px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <h2 style={{ marginBottom: "10px" }}>API Keys Details</h2>
            <p> API ID: {dataDtl[0].api_id}</p>

            <div style={{ marginLeft: "auto" }}>
              <Button
                type="primary"
                onClick={() => {
                  if (dataDtlRows.length > 0) {
                    setDetailsEditRec((prev) => !prev);
                  } else {
                    setDetailsEditRec(true);
                    handleAddRow();
                  }
                }}
              >
                {!dataDtlRows[0]?.api_dtl_id ? "Add New" : detailsEditRec ? "View" : "Edit"}
              </Button>

              {detailsEditRec && (
                <Button
                  type="default"
                  onClick={handleAddRow}
                  style={{ marginLeft: 10 }}
                >
                  Add Row
                </Button>
              )}
            </div>
            <Table
              dataSource={dataDtlRows}
              rowKey="api_dtl_id"
              style={{ marginTop: "20px", width: "100%" }}
              pagination={false}
            >
              <Column
                title={detailsEditRec ? "Active" : "API Detail ID"}
                dataIndex={detailsEditRec ? "is_active" : "api_dtl_id"}
                render={(text, record, index) =>
                  detailsEditRec ? (
                    <Switch
                      checked={record.is_active} // Bind the switch to is_active state
                      onChange={() => handleToggleActive(index)} // Toggle active state on switch change
                    />
                  ) : (
                    <span>{text}</span>
                  )
                }
              />
              <Column
                title="Display Column"
                dataIndex="display_column"
                render={(text, record, index) =>
                  detailsEditRec ? (
                    <Input
                      value={text}
                      onChange={(e) => {
                        const newRows = [...dataDtlRows];
                        newRows[index].display_column = e.target.value;
                        setDataDtlRows(newRows);
                      }}
                    />
                  ) : (
                    <span>{text}</span>
                  )
                }
              />
              <Column
                title="JSON Column"
                dataIndex="json_column"
                render={(text, record, index) =>
                  detailsEditRec ? (
                    <Input
                      value={text}
                      onChange={(e) => {
                        const newRows = [...dataDtlRows];
                        newRows[index].json_column = e.target.value;
                        setDataDtlRows(newRows);
                      }}
                    />
                  ) : (
                    <span>{text}</span>
                  )
                }
              />
              {detailsEditRec && (
                <Column
                  title="Action"
                  render={(text, record, index) => (
                    <Button type="link" onClick={() => handleDeleteRow(index)}>
                      Delete
                    </Button>
                  )}
                />
              )}
            </Table>
            <div
              style={{
                padding: "10px",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              {detailsEditRec && (
                <div>
                  <Button
                    type="primary"
                    onClick={() => handleSave(dataDtl[0]?.api_id)}
                    style={{ marginLeft: 10 }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
    </Card></div>
  );
}
  export default ApiMaster;
