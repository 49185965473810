// src/routes.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Home from './Home';
import Login from './Login';
import Signup from './Signup';
import Clients from './Clients';
import ClientToken from './ClientToken';
import ResponsePrivilege from './ResponsePrivilege';
import ProtectedRoute from './ProtectedRoute';
import RoleManagement from './Role';
import RoleAPI from './RoleApiMapping';
import UserMapping from './UserMapping';
import ApiMaster from './ApiMaster';

const RoutesConfig = () => {
  const { isAuthenticated } = useAuth(); // Get authentication state

  
  const routes = [
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
    },
    { path: '/login', element: isAuthenticated ? <Navigate to="/" /> : <Login /> },

    {
      path: '/signup',
      element: (
        <ProtectedRoute>
          <Signup />
        </ProtectedRoute>
      ),
    },
    {
      path: '/client/creation',
      element: (
        <ProtectedRoute>
          <Clients />
        </ProtectedRoute>
      ),
    },
    {
        path: '/client/token',
        element: (
          <ProtectedRoute>
            <ClientToken />
          </ProtectedRoute>
        ),
      },
      {
        path: '/client/token',
        element: (
          <ProtectedRoute>
            <ClientToken />
          </ProtectedRoute>
        ),
      },
      {
        path: '/user/privilege',
        element: (
          <ProtectedRoute>
            <ResponsePrivilege />
          </ProtectedRoute>
        ),
      },
      {
        path: '/user/Role',
        element: (
          <ProtectedRoute>
            <RoleManagement />
          </ProtectedRoute>
        ),
      },
      {
        path: '/user/RoleApI',
        element: (
          <ProtectedRoute>
            <RoleAPI />
          </ProtectedRoute>
        ),
      },
      {
        path: '/user/ApiUser',
        element: (
          <ProtectedRoute>
            <UserMapping />
          </ProtectedRoute>
        ),
      },
      {
        path: '/user/apiData',
        element: (
          <ProtectedRoute>
            <ApiMaster />
          </ProtectedRoute>
        ),
      },


    {
      path: '*',
      element: <Navigate to="/" />, // Redirect unknown routes to home
    },
  ];

  return routes;
};

export default RoutesConfig;
