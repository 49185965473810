    import React, { useEffect, useState } from 'react';
    import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
    import axiosInstance from './config/axiosInstance'; // Use the configured Axios instance
    import config from './config/config';
    import { useNavigate } from 'react-router-dom'; // Import useNavigate

    const ResponsePrivilege = () => {
        const [clients, setClients] = useState([]);
        const [users, setUsers] = useState([]);
        const [roles, setRoles] = useState([]);
        const [apis, setApis] = useState([]);
        const [apiDetails, setApiDetails] = useState(null);
        const [selectedClient, setSelectedClient] = useState('');
        const [selectedUser, setSelectedUser] = useState('');
        const [selectedRole, setSelectedRole] = useState('');
        const [selectedAPI, setSelectedAPI] = useState('');
        const [apiKeys, setApiKeys] = useState([]);
        const [currentApiId, setCurrentApiId] = useState(null);
        const [selectAll, setSelectAll] = useState(false);
        const [successMessage, setSuccessMessage] = useState('');
        const navigate = useNavigate(); // Initialize useNavigate

        // Fetch clients from the API
        const fetchClients = async () => {
            try {
                const response = await axiosInstance.get(`${config.API_CLIENT_URL}`);
                setClients(response.data);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        // Fetch users independently
        const fetchUsers = async () => {
            try {
                const response = await axiosInstance.get(`${config.API_USERS_URL}`);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        // Fetch roles based on selected user
        const fetchRoles = async (userId) => {
            if (!userId) {
                setRoles([]);
                return;
            }
            try {
                const response = await axiosInstance.get(`${config.API_USERS_URL}/${userId}/roles`);
                debugger;
                if(response.data !="")
                {  setRoles(response.data);}
                else{
                setRoles([]);
                }

                if (response.data.length > 0) {
                    setSelectedRole(response.data[0].role_id); // Set first role as default
                } else {
                    setSelectedRole(''); // Reset if no roles are available
                }

            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };

        // Fetch APIs based on selected role
        const fetchApis = async (roleId) => {
            if (!roleId) {
                setApis([]);
                return;
            }
            try {
                const response = await axiosInstance.get(`${config.API_ROLE_URL}/${roleId}/apis`);
                setApis(response.data);
                if (response.data.length > 0) {
                    setSelectedAPI(response.data[0].api_id); // Set first API as default
                } else {
                    setSelectedAPI(''); // Reset if no APIs are available
                }

            } catch (error) {
                console.error('Error fetching APIs:', error);
            }
        };

        const fetchApiKeys = async (clientId, roleId, apiId) => {
            if (!clientId || !roleId || !apiId) {
                setApiKeys([]);
                return;
            }
            try {
                const response = await axiosInstance.post(`${config.API_KEYS_URL}`, {
                    clientId,
                    roleId,
                    apiId
                });
                setApiKeys(response.data.output);
            } catch (error) {
                console.error('Error fetching API keys:', error);
            }
        };

        // Fetch API details based on selected API
        const fetchApiDetails = async (apiId) => {
            if (!apiId) {
                setApiDetails(null);
                return;
            }
            try {
                const response = await axiosInstance.get(`${config.API_API_DETAILS_URL}/${apiId}`);
                setApiDetails(response.data);
            } catch (error) {
                console.error('Error fetching API details:', error);
            }
        };

        // Handle form submission
        const handleSubmit = async () => {

            debugger;
            const selections = apiKeys.map(key => ({
                api_dtl_id: key.api_dtl_id,
                selection: key.selection // Assuming the structure has 'selected' to determine the checkbox state
            }));

            debugger;
            try {
                const response = await axiosInstance.post(`${config.API_SAVE_URL}`, {
                    apiId: currentApiId,
                    clientId:selectedClient,
        roleId:selectedRole,
        
                    selections:selections

                    
                });
            
                setSuccessMessage(response.data.message); // Set success message
                // Reload or reset the state as needed
                  // Reset dropdowns after submission
        setSelectedClient(''); // Reset client selection
        setSelectedUser(''); // Reset user selection
        setSelectedRole(''); // Reset role selection
        setSelectedAPI(''); // Reset API selection
        setApiKeys([]); // Reset API keys
        setApis([]); // Reset APIs
        setRoles([]); // Reset roles
        setCurrentApiId(null); // Reset current API ID
        setSelectAll(false); // Reset Select All checkbox
                setTimeout(() => {
                    navigate('/user/privilege'); // Change this path to your desired route
                }, 1000); // 2 seconds delay for user to read the message
            } catch (error) {
                console.error('Error saving selections:', error);
            }
        };

        // Effect to fetch clients on component mount
        useEffect(() => {
            fetchClients();
            fetchUsers(); // Fetch users independently on component mount
        }, []);

        // Effect to fetch roles when the selected user changes
        useEffect(() => {
            fetchRoles(selectedUser);
        }, [selectedUser]);

        // Effect to fetch APIs when the selected role changes
        useEffect(() => {
            fetchApis(selectedRole);
        }, [selectedRole]);


    // Effect to fetch API keys when the selected API, client, or role changes
    useEffect(() => {
        fetchApiKeys(selectedClient, selectedRole, selectedAPI);
    }, [selectedClient, selectedRole, selectedAPI]);



        // Handle the "Select All" checkbox
        const handleSelectAll = (e) => {
            setSelectAll(e.target.checked);
            setApiKeys(apiKeys.map(key => ({ ...key, selection: e.target.checked }))); // Set all to selected
        };

        // Handle individual checkbox changes
        const handleCheckboxChange = (key) => {
            debugger;
            setApiKeys(apiKeys.map(item => 
                item.api_dtl_id === key ? { ...item, selection: !item.selection } : item
            ));
        };

        return (
            <div className="container mt-5">
                <div className="card shadow-lg p-4">
                    <h4 style={{ textAlign: 'center', marginBottom: '20px', color: '#1890ff' }}>
                        <i className="fas fa-cogs"></i>Client API Privileges Mapping
                    </h4>

                    {/* Client Dropdown */}
                    <div className="form-group w-50 mx-auto">
                        <label htmlFor="clientSelect">Client:</label>
                        <div className="col-sm-12"> 
                        <select
                            id="clientSelect"
                            className="form-control "
                            value={selectedClient}
                            onChange={(e) => {
                                setSelectedClient(e.target.value);
                                setApis([]); // Reset APIs
                                setApiKeys([]); // Reset API Keys
                                setSelectedRole(''); // Reset role selection
                            }}
                        >
                            <option value="">Select a Client</option>
                            {clients.map(client => (
                                <option key={client.id} value={client.id}>
                                    {client.client_name}
                                </option>
                            ))}
                        </select></div>
                    </div>

                    {/* User Dropdown */}
                    <div className="form-group w-50 mx-auto">
                        <label htmlFor="userSelect">User:</label>
                        <select
                            id="userSelect"
                            className="form-control"
                            value={selectedUser}
                            onChange={(e) => {
                                setSelectedUser(e.target.value);
                                setSelectedRole(''); // Reset role selection
                                setApis([]); // Reset APIs
                                setApiKeys([]); // Reset API Keys
                            }}
                        >
                            <option value="">Select a User</option>
                            {users.map(user => (
                                <option key={user.id} value={user.id}>
                                    {user.username}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Role Dropdown */}
                    <div className="form-group w-50 mx-auto">
                        <label htmlFor="roleSelect">Role:</label>
                        <select
                            id="roleSelect"
                            className="form-control"
                            value={selectedRole}
                            onChange={(e) => {
                                setSelectedRole(e.target.value);
                                setApis([]); // Reset APIs
                            }}
                        >
                            <option value="">Select a Role</option>
                            {roles.map(role => (
                                <option key={role.role_id} value={role.role_id}>
                                    {role.role_desc}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* API Dropdown */}
                    <div className="form-group w-50 mx-auto">
                        <label htmlFor="apiSelect">API:</label>
                        <select
                            id="apiSelect"
                            className="form-control"
                            value={selectedAPI}
                            onChange={(e) => {
                                setSelectedAPI(e.target.value);
                                setCurrentApiId(e.target.value);
                            }}
                        >
                            <option value="">Select an API</option>
                            {apis.map(api => (
                                <option key={api.api_id} value={api.api_id}>
                                    {api.api_name}
                                </option>
                            ))}
                        </select>
                    </div>

                
    {/* Checkboxes for API Keys */}
    {apiKeys.length > 0 ? (
        
    <div id="responseKeys" className="table-responsive mt-3">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">
                            <input
                                type="checkbox"
                                id="selectAll"
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th scope="col">Display Column</th>
                        <th scope="col">JSON Column</th>
                    </tr>
                </thead>
                <tbody>
                    {apiKeys.map((key) => (
                        <tr key={key.api_dtl_id}>
                            <td>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`key-${key.api_dtl_id}`} // Unique ID for accessibility
                                    checked={key.selection || false}
                                    onChange={() => handleCheckboxChange(key.api_dtl_id)} // Call function to update state
                                />
                            </td>
                            <td>{key.display_column}</td>
                            <td>{key.json_column}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        ):[] }


    <div className="form-group  mx-auto">

                    {/* Button to submit the form */}
                    <button
                        id="submitButton"
                        className="btn btn-primary mt-3 btn-sm"
                        onClick={handleSubmit}
                    >
                        Save
                    </button></div>
                    {/* Success Message */}
                    {successMessage && (
                        <div className="alert alert-success mt-3" role="alert">
                            {successMessage}
                        </div>
                    )}

                    {/* Container to display the selected API details */}
                    {apiDetails && (
                        <div className="mt-4">
                            <h5>API Details:</h5>
                            <pre>{JSON.stringify(apiDetails, null, 2)}</pre>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    export default ResponsePrivilege;
