// import React, { useState, useEffect } from 'react';
// import { Table, Button, Modal, Form, Select, message } from 'antd';
// import axios from 'axios';
// import config from '../src/config/config';

// const { Option } = Select;

// const RoleAPI = () => {
//     const [roleApiMappings, setRoleApiMappings] = useState([]);
//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const [editingMapping, setEditingMapping] = useState(null);
//     const [roles, setRoles] = useState([]);
//     const [apis, setApis] = useState([]);
    
//     const [form] = Form.useForm();

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const [roleResponse, apiResponse, mappingResponse] = await Promise.all([
//                     axios.get(`${config.API_ROUTE_URL}/role`),
//                     axios.get(`${config.API_ROUTE_URL}/adm_api_hdr`),
//                     axios.get(`${config.API_ROUTE_URL}/role_api_mappings`)
//                 ]);

//                 setRoles(roleResponse.data);
//                 setApis(apiResponse.data);

//                 const mappings = mappingResponse.data.map(mapping => ({
//                     key: mapping.id,
//                     role: roleResponse.data.find(role => role.role_id === mapping.role_id)?.role_desc || 'Unknown',
//                     api: apiResponse.data.find(api => api.api_id === mapping.api_id)?.api_name || 'Unknown',
//                     role_id: mapping.role_id,
//                     api_id: mapping.api_id
//                 }));
//                 console.log('====================================')
//                 console.log("Here Mapping log :",mappings);
//                 console.log('====================================')
//                 setRoleApiMappings(mappings);

//             } catch (error) {
//                 message.error('Failed to load data');
//             }
//         };

//         fetchData(); 
//     }, []); 

//     const showModal = (mapping = null) => {
//         setEditingMapping(mapping);
//         if (mapping) {
//             form.setFieldsValue({
//                 role: mapping.role_id,
//                 api: mapping.api_id,
//             });
//         } else {
//             form.resetFields();
//         }
//         setIsModalVisible(true);
//     };

//     // const handleSubmit = async (values) => {
//     //     const roleId = values.role;
//     //     const apiId = values.api;

//     //     if (!editingMapping) {
//     //         const existingMapping = roleApiMappings.find(
//     //             mapping => mapping.role_id === roleId && mapping.api_id === apiId
//     //         );

//     //         if (existingMapping) {
//     //             message.error('This role is already mapped to the selected API.');
//     //             return;
//     //         }
//     //     }

//     //     if (editingMapping) {
//     //         try {
//     //             await axios.put(`${config.API_ROUTE_URL}/role_api_mapping/edit`, {
//     //                 id: editingMapping.key,
//     //                 role_id: roleId,
//     //                 api_id: apiId
//     //             });

//     //             const updatedMapping = roleApiMappings.map(mapping =>
//     //                 mapping.key === editingMapping.key
//     //                     ? { ...mapping, role_id: roleId, api_id: apiId, role: roles.find(role => role.role_id === roleId)?.role_desc, api: apis.find(api => api.api_id === apiId)?.api_name }
//     //                     : mapping
//     //             );
//     //             setRoleApiMappings(updatedMapping);
//     //             message.success('Role-API mapping updated successfully');
//     //         } catch (error) {
//     //             message.error('Failed to update mapping');
//     //         }
//     //     } else {
//     //         try {
//     //             const response = await axios.post(`${config.API_ROUTE_URL}/role_api_mapping/post`, values);

//     //             const newMapping = {
//     //                 key: response.data.id,
//     //                 role: roles.find(role => role.role_id === roleId)?.role_desc,
//     //                 api: apis.find(api => api.api_id === apiId)?.api_name,
//     //                 role_id: roleId,
//     //                 api_id: apiId
//     //             };
//     //             setRoleApiMappings([...roleApiMappings, newMapping]);
//     //             message.success('Role-API mapping added successfully');
//     //         } catch (error) {
//     //             message.error('Failed to add mapping');
//     //         }
//     //     }

//     //     form.resetFields();
//     //     setIsModalVisible(false);
//     //     setEditingMapping(null);
//     // };

//     const handleSubmit = async (values) => {
//         console.log('====================================')
//         console.log('here values: ',values);
//         console.log('====================================')
//         const roleId = values.role;
//         const apiId = values.api;
    
//         try {
    
            
    
//             if (editingMapping) {
//                 // Update the local state for an existing mapping
//                 const updatedMapping = roleApiMappings.map(mapping =>
//                     mapping.key === editingMapping.key
//                         ? { ...mapping, role_id: roleId, api_id: apiId, role: roles.find(role => role.role_id === roleId)?.role_desc, api: apis.find(api => api.api_id === apiId)?.api_name }
//                         : mapping
//                 );
//                 setRoleApiMappings(updatedMapping);
//                 message.success('Role-API mapping updated successfully');
//             } else {
//                 const payload = {
//                     id: editingMapping ? editingMapping.key : undefined,  // Pass the ID if editing
//                     role_id: roleId,
//                     api_id: apiId
//                 };
//                 console.log("Here Payload data :",payload);
        
//                 const response = await axios.post(`${config.API_ROUTE_URL}/role_api_mapping/post`, payload);
//                 // Add the new mapping to the local state
//                 const newMapping = {
//                     key: response.data.id,
//                     role: roles.find(role => role.role_id === roleId)?.role_desc,
//                     api: apis.find(api => api.api_id === apiId)?.api_name,
//                     role_id: roleId,
//                     api_id: apiId
//                 };
//                 setRoleApiMappings([...roleApiMappings, newMapping]);
//                 message.success('Role-API mapping added successfully');
//             }
    
//             form.resetFields();
//             setIsModalVisible(false);
//             setEditingMapping(null);
    
//         } catch (error) {
//             message.error('Failed to save/update mapping');
//         }
//     };
    

//     const handleDeleteMapping = async (key) => {
//         try {
//             await axios.delete(`${config.API_ROUTE_URL}/role_api_mapping/delete`, { data: { id: key } });
//             setRoleApiMappings(roleApiMappings.filter(mapping => mapping.key !== key));
//             message.success('Mapping deleted successfully');
//         } catch (error) {
//             message.error('Failed to delete mapping');
//         }
//     };

//     const columns = [
//         {
//             title: 'Role',
//             dataIndex: 'role',
//             key: 'role',
//         },
//         {
//             title: 'API',
//             dataIndex: 'api',
//             key: 'api',
//         },
//         {
//             title: 'Actions',
//             key: 'actions',
//             render: (_, record) => (
//                 <>
//                     <Button onClick={() => showModal(record)} style={{ marginRight: "20px" }}>Edit</Button>
//                     <Button danger onClick={() => handleDeleteMapping(record.key)}>Delete</Button>
//                 </>
//             ),
//         },
//     ];

//     return (
//         <>
//             <h4 style={{ gap: '20px', padding: '20px', textAlign: 'center' }}>Role-API Mapping</h4>
//             <Button type="primary" onClick={() => showModal()}>Add Mapping</Button>
//             <Table columns={columns} style={{ marginTop: "20px" }} dataSource={roleApiMappings} rowKey="key" />

//             <Modal
//                 title={editingMapping ? "Edit Role-API Mapping" : "Add New Role-API Mapping"}
//                 visible={isModalVisible}
//                 onCancel={() => setIsModalVisible(false)}
//                 footer={null}
//             >
//                 <Form form={form} onFinish={handleSubmit}>
//                     <Form.Item
//                         name="role"
//                         label="Role"
//                         rules={[{ required: true, message: 'Please select a role!' }]}
//                     >
//                         <Select placeholder="Select a role">
//                             {roles.map(role => (
//                                 <Option key={role.role_id} value={role.role_id}>{role.role_desc}</Option>
//                             ))}
//                         </Select>
//                     </Form.Item>
//                     <Form.Item
//                         name="api"
//                         label="API"
//                         rules={[{ required: true, message: 'Please select an API!' }]}
//                     >
//                         <Select placeholder="Select an API">
//                             {apis.map(api => (
//                                 <Option key={api.api_id} value={api.api_id}>{api.api_name}</Option>
//                             ))}
//                         </Select>
//                     </Form.Item>
//                     <Form.Item>
//                         <Button type="primary" htmlType="submit">
//                             {editingMapping ? 'Update' : 'Save'}
//                         </Button>
//                     </Form.Item>
//                 </Form>
//             </Modal>
//         </>
//     );
// };

// export default RoleAPI;

// -------------------------------x-----------------------

// import React, { useState, useEffect } from 'react';
// import { Table, Form, Select, Button, Modal, message } from 'antd';
// import axios from 'axios';
// import config from '../src/config/config';

// const RoleApiMapping = () => {
//     const [roles, setRoles] = useState([]);
//     const [apis, setApis] = useState([]);
//     const [mappings, setMappings] = useState([]);
//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const [editingMapping, setEditingMapping] = useState(null);
    
//     const [form] = Form.useForm();

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const [rolesResponse, apisResponse, mappingsResponse] = await Promise.all([
//                     axios.get(`${config.API_ROUTE_URL}/role`),
//                     axios.get(`${config.API_ROUTE_URL}/adm_api_hdr`),
//                     axios.get(`${config.API_ROUTE_URL}/role_api_mappings`) // Fetch existing mappings
//                 ]);
                
//                 setRoles(rolesResponse.data); // Set roles from the response
//                 setApis(apisResponse.data); // Set APIs from the response
//                 const mappingData = mappingsResponse.data.map(mapping => ({
//                     key: mapping.id, // Assuming the mapping object has an `id` field
//                     role_id: mapping.role_id,
//                     api_id: mapping.api_id,
//                 }));
//                 setMappings(mappingData); // Set mappings for the table
//             } catch (error) {
//                 message.error('Failed to load data');
//             }
//         };

//         fetchData(); 
//     }, []); 

//     const showModal = (mapping = null) => {
//         setEditingMapping(mapping);
//         if (mapping) {
//             form.setFieldsValue({
//                 role_id: mapping.role_id,
//                 api_id: mapping.api_id,
//             });
//         } else {
//             form.resetFields();
//         }
//         setIsModalVisible(true);
//     };

//     const handleSubmit = async (values) => {
//         try {
//             const { role_id, api_id } = values;

//             if (editingMapping) {
//                 // Edit existing mapping
//                 await axios.post(`${config.API_ROUTE_URL}/role_api_mapping/post`, {
//                     role_api_mapping_id: editingMapping.key, // ID from mapping to edit
//                     role_id,
//                     api_id,
//                 });
//                 message.success('Mapping updated successfully');
//             } else {
//                 // Add new mapping
//                 await axios.post(`${config.API_ROUTE_URL}/role_api_mapping/post`, {
//                     role_id,
//                     api_id,
//                 });
//                 message.success('Mapping added successfully');
//             }
//             // Refetch mappings after adding/updating
//             const response = await axios.get(`${config.API_ROUTE_URL}/role_api_mappings`);
//             const mappingData = response.data.map(mapping => ({
//                 key: mapping.id,
//                 role_id: mapping.role_id,
//                 api_id: mapping.api_id,
//             }));
//             setMappings(mappingData); // Update the state with the new mappings
//         } catch (error) {
//             message.error('Failed to save mapping');
//         } finally {
//             form.resetFields();
//             setIsModalVisible(false);
//             setEditingMapping(null);
//         }
//     };

//     const handleDeleteMapping = async (key) => {
//         try {
//             await axios.delete(`${config.API_ROUTE_URL}/role_api_mapping/delete`, { data: { id: key } });
//             setMappings(mappings.filter(mapping => mapping.key !== key));
//             message.success('Mapping deleted successfully');
//         } catch (error) {
//             message.error('Failed to delete mapping');
//         }
//     };

//     const columns = [
//         {
//             title: 'Role',
//             dataIndex: 'role_id',
//             key: 'role_id',
//             render: (text) => {
//                 const role = roles.find(role => role.role_id === text);
//                 return role ? role.role_desc : text; // Display role description
//             },
//         },
//         {
//             title: 'API',
//             dataIndex: 'api_id',
//             key: 'api_id',
//             render: (text) => {
//                 const api = apis.find(api => api.api_id === text);
//                 return api ? api.api_name : text; // Display API name
//             },
//         },
//         {
//             title: 'Actions',
//             key: 'actions',
//             render: (_, record) => (
//                 <>
//                     <Button onClick={() => showModal(record)} style={{ marginRight: "20px" }}>Edit</Button>
//                     <Button danger onClick={() => handleDeleteMapping(record.key)}>Delete</Button>
//                 </>
//             ),
//         },
//     ];

//     return (
//         <>
//             <h4 style={{ padding: '20px', textAlign: 'center' }}>Role-API Mapping</h4>
//             <Button type="primary" onClick={() => showModal()}>Add Mapping</Button>
//             <Table columns={columns} dataSource={mappings} rowKey="key" style={{ marginTop: "20px" }} />

//             <Modal
//                 title={editingMapping ? "Edit Mapping" : "Add New Mapping"}
//                 visible={isModalVisible}
//                 onCancel={() => setIsModalVisible(false)}
//                 footer={null}
//             >
//                 <Form form={form} onFinish={handleSubmit}>
//                     <Form.Item
//                         name="role_id"
//                         label="Role"
//                         rules={[{ required: true, message: 'Please select a role!' }]}
//                     >
//                         <Select placeholder="Select a role">
//                             {roles.map(role => (
//                                 <Select.Option key={role.role_id} value={role.role_id}>
//                                     {role.role_desc}
//                                 </Select.Option>
//                             ))}
//                         </Select>
//                     </Form.Item>
//                     <Form.Item
//                         name="api_id"
//                         label="API"
//                         rules={[{ required: true, message: 'Please select an API!' }]}
//                     >
//                         <Select placeholder="Select an API">
//                             {apis.map(api => (
//                                 <Select.Option key={api.api_id} value={api.api_id}>
//                                     {api.api_name}
//                                 </Select.Option>
//                             ))}
//                         </Select>
//                     </Form.Item>
//                     <Form.Item>
//                         <Button type="primary" htmlType="submit">
//                             {editingMapping ? 'Update' : 'Save'}
//                         </Button>
//                     </Form.Item>
//                 </Form>
//             </Modal>
//         </>
//     );
// };

// export default RoleApiMapping;

// -------------------x---------------

import React, { useState, useEffect } from 'react';
import { Table, Form, Select, Button, Modal, message, Card } from 'antd';
import axios from 'axios';
import config from '../src/config/config';

const RoleAPI = () => {
    const [roles, setRoles] = useState([]);
    const [apis, setApis] = useState([]);
    const [mappings, setMappings] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingMapping, setEditingMapping] = useState(null);
    
    const [form] = Form.useForm();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [rolesResponse, apisResponse, mappingsResponse] = await Promise.all([
                    axios.get(`${config.API_BASEURL}/role`),
                    axios.get(`${config.API_BASEURL}/adm_api_hdr`),
                    axios.get(`${config.API_BASEURL}/role_api_mappings`)
                ]);
                
                setRoles(rolesResponse.data);
                setApis(apisResponse.data);
                const mappingData = mappingsResponse.data.map(mapping => ({
                    key: mapping.api_map_id,
                    role_id: mapping.role_id,
                    api_id: mapping.api_id,
                }));
                setMappings(mappingData);
            } catch (error) {
                message.error('Failed to load data');
            }
        };

        fetchData(); 
    }, []); 

    const showModal = (mapping = null) => {
        setEditingMapping(mapping);
        if (mapping) {
            form.setFieldsValue({
                role_id: mapping.role_id,
                api_id: mapping.api_id,
            });
        } else {
            form.resetFields();
        }
        setIsModalVisible(true);
    };

    const handleSubmit = async (values) => {
        try {
            const { role_id, api_id } = values;

            const payload = editingMapping ? {
                id: editingMapping.key, // ID for editing
                role_id,
                api_id,
            } : {
                role_id,
                api_id,
            };

            // Use a single POST request for both adding and editing
            await axios.post(`${config.API_BASEURL}/role_api_mapping/post`, payload);
            message.success(editingMapping ? 'Mapping updated successfully' : 'Mapping added successfully');

            // Refresh the mappings
            const response = await axios.get(`${config.API_BASEURL}/role_api_mappings`);
            const mappingData = response.data.map(mapping => ({
                key: mapping.api_map_id,
                role_id: mapping.role_id,
                api_id: mapping.api_id,
            }));
            setMappings(mappingData);
        } catch (error) {
            if(error.status === 400){
            message.warning("This API is already mapped to the role.");
            }else{
                message.error('Failed to save mapping,same role cannot Assign to existing API');

            }
        } finally {
            form.resetFields();
            setIsModalVisible(false);
            setEditingMapping(null);
        }
    };

    const handleDeleteMapping = async (key) => {
        try {
            await axios.delete(`${config.API_BASEURL}/role_api_mapping/delete`, { data: { id: key } });
            setMappings(mappings.filter(mapping => mapping.key !== key));
            message.success('Mapping deleted successfully');
        } catch (error) {
            message.error('Failed to delete mapping');
        }
    };

    const columns = [
        {
            title: 'Role',
            dataIndex: 'role_id',
            key: 'role_id',
             //sorter:(a,b)=>a.role_id.localCompare(a.role_id - b.role_id)
            render: (text) => {
                const role = roles.find(role => role.role_id === text);
                return role ? role.role_desc : text;
            },
        },
        {
            title: 'API',
            dataIndex: 'api_id',
            key: 'api_id',
            render: (text) => {
                const api = apis.find(api => api.api_id === text);
                return api ? api.api_name : text;
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button color ='primary' variant="solid" onClick={() => showModal(record)} style={{ marginRight: "20px"}}>Edit</Button>
                    <Button color="danger" variant="solid" onClick={() => handleDeleteMapping(record.key)}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <>
        <Card className="grid-card">
            <div >
  
            <h4 style={{ padding: '20px', textAlign: 'center',color:'#006dff' }}>Role-API Mapping</h4>
            <Button type="primary" onClick={() => showModal()}>Add</Button>
            <Table columns={columns} dataSource={mappings} rowKey="key" style={{ marginTop: "20px" }} />

            <Modal
                title={editingMapping ? "Edit Mapping" : "Add New Mapping"}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="role_id"
                        label="Role"
                        rules={[{ required: true, message: 'Please select a role!' }]}
                    >
                        <Select placeholder="Select a role">
                            {roles.map(role => (
                                <Select.Option key={role.role_id} value={role.role_id}>
                                    {role.role_desc}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="api_id"
                        label="API"
                        rules={[{ required: true, message: 'Please select an API!' }]}
                    >
                        <Select placeholder="Select an API">
                            {apis.map(api => (
                                <Select.Option key={api.api_id} value={api.api_id}>
                                    {api.api_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {editingMapping ? 'Update' : 'Save'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            </div></Card>
        </>
    );
};

export default RoleAPI;

