import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Card, Spin } from 'antd';
import axios from 'axios';
import config from '../src/config/config';

const RoleManagement = () => {
    const [roles, setRoles] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRole, setEditingRole] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state
    
    const [form] = Form.useForm();

    useEffect(() => {
        const fetchRoles = async () => {
            setLoading(true); // Start loading
            try {
                const response = await axios.get(`${config.API_BASEURL}/role`);
                const roleData = response.data.map(role => ({
                    key: role.role_id,       // Use role_id as the key
                    id: role.role_id,        // Store role_id for display
                    name: role.role_desc,    // Store role_desc for display
                }));
                setRoles(roleData);
            } catch (error) {
                message.error('Failed to load roles');
            }
            finally {
                setLoading(false); // Stop loading
            }
        };
        fetchRoles(); 
    }, []); 

    const showModal = (role = null) => {
        setEditingRole(role);
        if (role) {
            // Pre-fill the form fields when editing
            form.setFieldsValue({
                name: role.name,
            });
        } else {
            form.resetFields();
        }
        setIsModalVisible(true);
    };

    const handleSubmit = async (values) => {
        const roleName = values.name;  // Capture the role name
        
 
        if (!editingRole) {
            const existingRole = roles.find(role => role.name === roleName);
            if (existingRole) {
                message.error('This role name already exists.');
                return;
            }
        }

        try {
            // Use POST for both adding and updating
            setLoading(true); // Start loading
            const response = await axios.post(`${config.API_BASEURL}/role/post`, {
                role_desc: roleName,
                role_id: editingRole ? editingRole.id : undefined 
            });

            if (editingRole) {
                // If editing, update the role in the local state
                const updatedRoles = roles.map(role =>
                    role.key === editingRole.key ? { ...role, name: roleName } : role
                );
                setRoles(updatedRoles);
                message.success('Role updated successfully');
            } else {
                // If adding, create a new role object
                const newRole = {
                    key: response.data.role_id, // ID from backend response
                    id: response.data.role_id,   // Store role_id for display
                    name: roleName,
                };
                setRoles([...roles, newRole]);
                message.success('Role added successfully');
            }
        } catch (error) {
            message.error('Failed to save role');
                
        }
        finally {
            setLoading(false); // Stop loading
            form.resetFields();
            setIsModalVisible(false);
            setEditingRole(null);
        }

      
    };

    const handleDeleteRole = async (key) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this role?',
            onOk: async () => {
        try {
            await axios.delete(`${config.API_BASEURL}/role/delete`, { data: { role_id: key } });
            setRoles(roles.filter(role => role.key !== key));
            message.success('Role deleted successfully');
        } catch (error) {
            message.error('Failed to delete role');
        }
    },
});
};

    const columns = [
        {
            title: 'Role ID',          // New column for Role ID
            dataIndex: 'id',          // The key in your role data
            key: 'id',
            sorter: (a, b) => a.id - b.id, // Sorting for Role ID
        },
        {
            title: 'Role Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button color='primary' variant="solid" onClick={() => showModal(record)} style={{ marginRight: "20px" }}>Edit</Button>
                    <Button color='danger' variant="solid" onClick={() => handleDeleteRole(record.key)}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <>
  <Card className="grid-card">
            <div >
        

            <h4 style={{ gap: '20px', padding: '20px', textAlign: 'center',color:'#006dff' }}>Role Management</h4>
            <Button type="primary" onClick={() => showModal()}>Add</Button>
            {loading ? <Spin style={{ marginTop: "20px" }} /> : <Table columns={columns} style={{ marginTop: "20px" }} dataSource={roles} rowKey="key" pagination={{ pageSize: 5 }}  />}

            <Modal
                title={editingRole ? "Edit Role" : "Add New Role"}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="name"
                        label="Role Name"
                        rules={[{ required: true, message: 'Please enter a role name!' }]}
                    >
                        <Input placeholder="Enter role name" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {editingRole ? 'Update' : 'Save'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            </div></Card>
        </>
    );
};

export default RoleManagement;
